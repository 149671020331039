import moment from 'moment'
import React from 'react'
import {AnnotateIcon} from './recharts/AnnotateDotChart'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const FORMAT_MM_DD = 'MM/DD'

// Colors to render the line on chart. First line is first value, 2nd line is 2nd color
// Values from 2 element to end are just added for safety is index > 1 is used
export const LINE_COLOR = ['#8884d8', '#82ca9d', '#F1E6B1', '#8884d8', '#8884d8', ]
export const STROKE_FILL_20 = ['#6E68B7', '#68A17D', '#C0B88D', '#8884d8', '#8884d8', ]
export const STROKE_FILL_40 = ['#584692', '#4E795E', '#908A6A', '#8884d8', '#8884d8', ]

const metric_label_mapping = {
  acc: 'Accuracy',
  prec: 'Precision',
  fn: 'False Neg',
  tn:'True Neg',
  fp:'False Pos',
  tp:'True Pos',
  rec: 'Recall',
  f1: 'F1 Score',
  sen: 'Sensitivity',
  sp: 'Specificity',
  requests: 'Predictions'
};

const chart_method_metric = [
  { "method": "llm.counts", "metric": "avg_qs_per_conv", 
    "title": "Interaction Rate", "yaxis_label": "Avg Ques.",
    "tooltip": "Average number of questions per thread"},
  { "method": "llm.counts", "metric": "convo_counts", 
    "title": "Number of Threads", "yaxis_label": "Threads"},
  { "method": "llm.counts", "metric": "llm_req_counts", "title": "Cost per Thread", "yaxis_label": "$/Conv",
    "tooltip": "Average cost per thread of LLM API calls."},
  { "method": "llm.counts", "metric": "llm_req_counts", 
    "title": "Total LLM Requests", "yaxis_label": "LLM requests"},
]

export const getChartMetadata = (method, metric) => {
  const record = chart_method_metric.find(item => item.method === method && item.metric === metric);
  return record || {
    method: "",
    metric: "",
    title: "",
    yaxis_label: "",
    tooltip: ""
  };
};

export const getChartMetadataFromQuery = (query) => {
  if (!query) {
    return getChartMetadata("", ""); // handle empty query scenario
  }

  const method = query.instructions && query.instructions.method ? query.instructions.method : "";
  const metric = query.metric || "";

  return getChartMetadata(method, metric);
};

export const format_ts = (value) => {
  return moment(value).format(FORMAT_MM_DD)
}

export const format_ts_event = (value) => {
  return custom_format_ts(value, 'MM/DD/YY hh:mm:ss')
}

export const custom_format_ts = (value, format_str) => {
  return moment(value).format(format_str)
}

export const format_tip = (value, name) => {
  // export const format_tip = (value, name, props) => {
  // 
  // 
  return moment(value).format(FORMAT_MM_DD)
}

export const getAnnotation = (label, annotations) => {
  let annotate = undefined
  annotate = annotations.find(({ts_numeric}) => ts_numeric === label)
  if(annotate && annotate.severity){
  }


  // if (annotate !== undefined) {
  //     
  //     
  //     note = annotate.note
  // }
  return annotate
}

export const get_model_performance_chart_label = (key) => {
  return metric_label_mapping[key] || '';
}

export const ChartTooltipWithAnnotate = ({active, payload, label, annotations}) => {
  // Label value is x value, this is to be used to find object with this key/column value in the
  // input dict

  const listItems = payload && payload.map((item, index) => {
    return (
        <li
          className='recharts-tooltip-item'
          // style={{display: 'block', paddingTop: 4, paddingBottom: 4, color: 'rgb(136, 132, 216)'}}>
          style={{display: 'block', paddingTop: 4, paddingBottom: 4, color: `${LINE_COLOR[index]}`}}
          key={index}
        >
          <span className='recharts-tooltip-item-name'>{`${get_model_performance_chart_label(item.dataKey)}`}</span>
          <span className='recharts-tooltip-item-separator'>: </span>
          <span className='recharts-tooltip-item-value'>{`${formatValue(item.value, 2)}`}</span>
        </li>
    )
  });

  if (active && payload && payload.length) {
  const annotate = getAnnotation(label, annotations)
    return (
      <div
        className='recharts-default-tooltip'
        style={{
          margin: 0,
          padding: 10,
          backgroundColor: 'rgb(255, 255, 255)',
          border: '1px  solid rgb(204, 204, 204)',
          whiteSpace: 'nowrap',
        }}
      >
        <p className='recharts-tooltip-label' style={{margin: 0}}>{`${format_ts(label)}`}</p>
        <ul className='recharts-tooltip-item-list' style={{padding: 0, margin: 0}}>
          {listItems}
          {annotate !== undefined && (
            <li
              className='recharts-tooltip-item'
              style={{
                display: 'block',
                paddingTop: 4,
                paddingBottom: 4,
                color: 'rgb(136, 132, 216)',
              }}
            >
              <span>{<AnnotateIcon annotate={annotate} />}</span>
              {/*<span className="recharts-tooltip-item-name">07/88 12:44</span>*/}
              {/*<span className="recharts-tooltip-item-separator">: </span>*/}
              <span className='recharts-tooltip-item-value'>
                <ReactMarkdown children={annotate.desc_markdown} rehypePlugins={[rehypeRaw]} />
              </span>
            </li>
          )}
        </ul>
      </div>
    )
  }
  return null
}

/**
 * Formats a value to a specified number of decimal places, if it can be converted to a number.
 * If the input is not a number or cannot be converted to a number, it is returned unchanged.
 * If the input is an integer, the default number of decimal places is 0.
 * If the input is a string, it can be truncated to a specified number of characters, with "..." added after it.
 *
 * @param {any} val - The number or string to format.
 * @param {number} [decimals=0] - The number of decimal places to show.
 * @param {number} [truncateChar] - The number of characters to truncate the input string to.
 * @returns {string|number} The formatted number or the original input, if it cannot be formatted.
 */
export function formatNumber(val, decimals = 0, truncateChar) {
  if (typeof val === 'string' && typeof truncateChar === 'number' && val.length > truncateChar) {
    val = val.substring(0, truncateChar) + '...';
  }

  if (!isNaN(val)) {
    const decimalPlaces = Number.isInteger(parseFloat(val)) ? 0 : decimals;
    return parseFloat(val).toFixed(decimalPlaces);
  } else {
    return val;
  }
}

/**
 * Formats a value to a specified number of decimal places, if it can be converted to a number.
 * If the input is not a number or cannot be converted to a number, it is returned unchanged.
 * If the input is an integer, the default number of decimal places is 0.
 * If the input is a string, it can be truncated to a specified number of characters, with "..." added after it.
 *
 * @param {any} val - The number or string to format.
 * @param {number} [precision] - The number of decimal places to show or the number of characters to truncate the input string to.
 * @returns {string|number} The formatted number or the original input, if it cannot be formatted.
 */

export function formatValue(val, precision) {
  if (!isNaN(val)) {
    const decimalPlaces = Number.isInteger(parseFloat(val)) ? 0 : (precision === undefined ? 2 : precision);
    let value =  parseFloat(val).toFixed(decimalPlaces);
    if(value === "NaN"){
      return "-"
    } else {
      return value;
    }
  } else {
    if (typeof val === 'string') {
      if (typeof precision === 'number') {
        val = val.length > precision ? val.substring(0, precision) + ' ...' : val;
      }
    }
  }
  return val;
}


/**
 * Formats a number to a specified number of decimal places, if it can be converted to a number.
 * If the input is not a number or cannot be converted to a number, it is returned unchanged.
 * If the input is an integer, the default number of decimal places is 0.
 *
 * @param {any} num - The number to format.
 * @param {number} [decimals=0] - The number of decimal places to show.
 * @returns {string|number} The formatted number or the original input, if it cannot be formatted.
 */
// export function formatValue(num, decimals = 2) {
//   if (!isNaN(num)) {
//     const decimalPlaces = Number.isInteger(parseFloat(num)) ? 0 : decimals;
//     return parseFloat(num).toFixed(decimalPlaces);
//   } else {
//     return num;
//   }
// }
